import { ICustomer14860 } from "../../../common/types";
import {
  CREATE_CUSTOMER14860,
  UPDATE_CUSTOMER14860,
  DELETE_CUSTOMER14860,
  FETCH_CUSTOMER14860,
  LISTEN_TO_SELECTED_CUSTOMER14860,
  FILTER_CUSTOMER14860,
} from "./customer14860Constants";

const initialState = {
  customer14860: [],
  selectedCustomer14860: null,
  lastVisible: null,
  filter: "all",
  startDate: new Date(),
  retainState: false,
  filterCustomer14860: {},
};

export default function customer14860Reducer(
  state = initialState,
  { type, payload }: any,
) {
  switch (type) {
    case CREATE_CUSTOMER14860:
      return {
        ...state,
        customer14860: [...state.customer14860, payload],
      };
    case UPDATE_CUSTOMER14860:
      return {
        ...state,
        customer14860: [
          ...state.customer14860.filter(
            (evt: ICustomer14860) => evt.id !== payload.id,
          ),
          payload,
        ],
      };
    case DELETE_CUSTOMER14860:
      return {
        ...state,
        customer14860: [
          ...state.customer14860.filter(
            (evt: ICustomer14860) => evt.id !== payload,
          ),
        ],
      };
    case FETCH_CUSTOMER14860:
      return {
        ...state,
        customer14860: payload,
      };
    case LISTEN_TO_SELECTED_CUSTOMER14860:
      return {
        ...state,
        selectedCustomer14860: payload,
      };
    case FILTER_CUSTOMER14860:
      return {
        ...state,
        filterCustomer14860: payload || {},
      };
    default:
      return state;
  }
}
