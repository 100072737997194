import { UserOutlined } from "@ant-design/icons";
import React from "react";
import { Link } from "react-router-dom";
import { Avatar, Dropdown, Menu } from "../../atoms";

export interface ILeftMenuProps {
  authenticated: boolean;
  login: () => void;
  logout: () => void;
  currentUser: any;
}

export const LeftMenu: React.FC<ILeftMenuProps> = ({
  authenticated,
  login,
  logout,
  currentUser,
}) => {
  const menu = (
    <Menu>
      <Menu.Item key="6">
        <Link to="#" onClick={logout}>
          {" "}
          Log out
        </Link>
      </Menu.Item>
    </Menu>
  );
  const menuLogIn = (
    <Menu>
      <Menu.Item key="0">
        <Link to="#" onClick={login}>
          {" "}
          Σύνδεση{" "}
        </Link>
      </Menu.Item>
    </Menu>
  );
  return (
    <>
      {authenticated ? (
        <Dropdown overlay={menu}>
          <Avatar size={40}>{currentUser?.firstName}</Avatar>
        </Dropdown>
      ) : (
        <Dropdown overlay={menuLogIn}>
          <Avatar size={40} icon={<UserOutlined />} />
        </Dropdown>
      )}
    </>
  );
};
