import { IQueryConfigArgs } from "../../common/types";
import { db, collectionWithArgs } from "./firestoreService";
import { COLLECTION_CUSTOMER14860 } from "./firebaseConstants";

export function fetchCustomer14860FromFirestore(args?: IQueryConfigArgs[]) {
  const ref = db.collection(COLLECTION_CUSTOMER14860);
  return collectionWithArgs(ref, args || []);
}
export function listenToCustomer14860FromFirestore(id: string) {
  return db.collection(COLLECTION_CUSTOMER14860).doc(id);
}

export async function checkUserInFirestore(idNumber: string) {
  try {
    const currentCustomer = await db
      .collection(COLLECTION_CUSTOMER14860)
      .doc(idNumber)
      .get();
    if (currentCustomer.exists) {
      sessionStorage.setItem("userId", idNumber);
      return currentCustomer.data();
    }
    throw new Error("User do exist");
  } catch (err) {
    throw err;
  }
}
