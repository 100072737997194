import { FilterValue } from "antd/lib/table/interface";
import { ICustomer14860 } from "../../../common/types";
import {
  UPDATE_CUSTOMER14860,
  DELETE_CUSTOMER14860,
  FETCH_CUSTOMER14860,
  LISTEN_TO_SELECTED_CUSTOMER14860,
  FILTER_CUSTOMER14860,
} from "./customer14860Constants";

export function listenToCustomer14860(customer14860: ICustomer14860[]) {
  return {
    type: FETCH_CUSTOMER14860,
    payload: customer14860,
  };
}

export function listenToSelectedCustomer14860(customer14860: ICustomer14860) {
  return {
    type: LISTEN_TO_SELECTED_CUSTOMER14860,
    payload: customer14860,
  };
}

export function updateCustomer14860(customer14860: ICustomer14860) {
  return {
    type: UPDATE_CUSTOMER14860,
    payload: customer14860,
  };
}

export function deleteCustomer14860(customer14860Id: string) {
  return {
    type: DELETE_CUSTOMER14860,
    payload: customer14860Id,
  };
}

export function listenToCustomer14860Filter(
  filters: Record<string, FilterValue | null>,
) {
  return {
    type: FILTER_CUSTOMER14860,
    payload: filters,
  };
}
