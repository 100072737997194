/* eslint-disable no-console */
import { LockOutlined, UserOutlined } from "@ant-design/icons";
import React from "react";
import { notification } from "antd";
import { useDispatch } from "react-redux";
import { Alert, Form, Input, Logo } from "../../atoms";
import { ModalWrapper } from "../../../common/modals/ModalWrapper";
import { closeModal } from "../../../redux/data/modals/modalReducer";
import { checkUserInFirestore } from "../../../firestore/firestoreService";
import { listenToSelectedCustomer14860 } from "../../../redux/data/customer14860/customer14860Actions";
import { LoginLogoWrapper } from "./LoginForm.style";

export const LoginForm: React.FC = (props) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState("");
  const [, forceUpdate] = React.useState({});

  // To disable submit button at the beginning.
  React.useEffect(() => {
    forceUpdate({});
  }, []);

  const handleSubmit = async () => {
    try {
      const values: any = await form.validateFields();
      setLoading(true);
      const customer = await checkUserInFirestore(values.idNumber);

      dispatch(listenToSelectedCustomer14860(customer as any));
      localStorage.setItem("userId", values.idNumber);
      setLoading(false);
      dispatch(closeModal());
      notification.success({
        message: "Καλωσορίσατε στην ΠΑΣΥΔΥ πύλη",
        description: "Έχετε συνδεθεί επιτυχώς",
      });
    } catch (errorInfo: any) {
      console.log({ errorInfo });
      setLoading(false);
      setError("Πρόβλημα με το ονοματεπώνυμο ή τον αρ.Ταυτότητας");
    }
  };
  return (
    <ModalWrapper
      {...props}
      onSave={handleSubmit}
      loading={loading}
      closable={false}
      okText={"Σύνδεση"}
    >
      <LoginLogoWrapper>
        <Logo />
        <div className="header-pasydy-logo-name">για</div>
        <img className="header-pasydy-logo" src="/assets/PASYDY.png" alt="" />
      </LoginLogoWrapper>
      {error !== "" && (
        <Alert message={error} type="error" style={{ marginBottom: 20 }} />
      )}
      <Form form={form} name="horizontal_login" layout="vertical">
        <div style={{ color: "#faa11c", marginBottom: 20 }}>
          Οι προσφορές του Poupas for Holidays για ΠΑΣΥΔΥ είναι μόνο για τα μέλη
          της ΠΑΣΥΔΥ. Παρακαλώ συμπληρώστε τα πιο κάτω στοιχεία ώστε να
          επιβεβαιώσουμε το μέλος και να προχωρήσετε στις προσφορές.{" "}
        </div>
        <Form.Item
          name="name"
          label="Ονοματεπώνυμο (όπως εμφανίζεται στη ταυτότητα σας)"
          rules={[
            {
              required: true,
              message: "Παρακαλώ καταχωρίστε το ονοματεπώνυμο σας",
            },
          ]}
        >
          <Input
            prefix={<UserOutlined className="site-form-item-icon" />}
            placeholder="Ονοματεπώνυμο"
          />
        </Form.Item>
        <Form.Item
          name="idNumber"
          label="Αρ. Ταυτότητας"
          rules={[
            {
              required: true,
              message: "Παρακαλώ καταχωρίστε τον αρ.Ταυτότητας σας",
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder="Αρ. Ταυτότητας"
          />
        </Form.Item>
      </Form>
    </ModalWrapper>
  );
};
