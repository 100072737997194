import styled from "styled-components";
export const LoginLogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  background-color: #37a7cd;
  padding: 10px;
  gap: 20px;
  align-items: center;
  margin-bottom: 30px;
  .header-pasydy-logo {
    height: 70px;
  }
  .header-pasydy-logo-name {
    color: #fff;
  }
`;
